import React, { Suspense } from "react";
import ProjectInfoBar from "./ProjectInfoBar";
import { Project } from "../../../../store/types/projects";

const componentLookUpTable = {
  "accounting-system": React.lazy(
    () =>
      import(
        /** @vite-ignore */
        "./custom-projects-display/" + "accounting-system" + ".tsx"
      )
  ),
  "portfolio-website": React.lazy(
    () =>
      import(
        /** @vite-ignore */
        "./custom-projects-display/" + "portfolio-website" + ".tsx"
      )
  ),
  "microservices-architecture-sport-betting": React.lazy(
    () =>
      import(
        /** @vite-ignore */
        "./custom-projects-display/" +
          "microservices-architecture-sport-betting" +
          ".tsx"
      )
  ),
  "retrieval-augmented-generation": React.lazy(
    () =>
      import(
        /** @vite-ignore */
        "./custom-projects-display/" + "retrieval-augmented-generation" + ".tsx"
      )
  ),
  "credit-risk-prediction": React.lazy(
    () =>
      import(
        /** @vite-ignore */
        "./custom-projects-display/" + "credit-risk-prediction" + ".tsx"
      )
  ),
};

function ProjectDisplay({ currentProject }: { currentProject: Project }) {
  console.log("looking up info_filename: ", currentProject.info_filename);
  const CustomPage = componentLookUpTable[currentProject.info_filename];
  console.log("CustomPage: ", CustomPage);

  return (
    <div id="projectView">
      <ProjectInfoBar
        name={currentProject.name}
        codeRepository={currentProject.code_repository_url}
        technologies={currentProject.technologies}
      />

      {CustomPage ? (
        <Suspense fallback={<p>loading....</p>}>
          <CustomPage />
        </Suspense>
      ) : (
        <p>No details found</p>
      )}
    </div>
  );
}

export default ProjectDisplay;
