import { useEffect } from "react";

/**
 * Creates an intersection observer to see if an element is into view.
 * @param param0
 */
const useTriggerAnimationOnScrollIntoView = (
  selector: string,
  className: string
) => {
  useEffect(() => {
    //credits: https://coolcssanimation.com/how-to-trigger-a-css-animation-on-scroll/
    // Create the observer
    const observer = new IntersectionObserver((entries) => {
      // We will fill in the callback later...
      entries.forEach((entry) => {
        // If the element is visible
        if (entry.isIntersecting) {
          // Add the animation class
          entry.target.classList.add(className);
        }
      });
    });

    // Tell the observer which elements to track
    const elements = document.querySelectorAll(selector);
    if (!elements) {
      throw new Error("no elements found with specified selector " + selector);
    }
    elements.forEach((element) => {
      observer.observe(element);
    });
  }, [selector, className]);
};

export default useTriggerAnimationOnScrollIntoView;
