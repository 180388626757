function TechnologyTag({ name }: { name: string }) {
  const styleTag = {
    display: "inline-block",
    borderRadius: "0.5rem",
    padding: "0.1rem 0.5rem 0.1rem 0.5rem",
    margin: "0 0.1rem 0 0.1rem",
  };
  return (
    <span style={styleTag} className="highlight">
      {name}
    </span>
  );
}

export default TechnologyTag;
