import TechnologyTag from "./TechnologyTag";
import { Technology } from "../../../../store/types/technologies";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function ProjectInfoBar({
  name,
  technologies,
  codeRepository,
}: {
  name: string;
  technologies: Technology[];
  codeRepository: string;
}) {
  return (
    <>
      <h1>{name}</h1>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0 1rem",
        }}
      >
        <ul className="no-list-style">
          {technologies.map((technology) => {
            return (
              <li style={{ display: "inline" }}>
                <TechnologyTag key={technology.id} name={technology.name} />
              </li>
            );
          })}
        </ul>
        <div>
          {codeRepository && (
            <a href={codeRepository} className="btn">
              <FontAwesomeIcon icon={faGithub} size="xl" /> view code
            </a>
          )}
        </div>
      </div>
    </>
  );
}

export default ProjectInfoBar;
