import { useContext, useState } from "react";
import Collapsible from "./Collapsible";
import { useLocation } from "react-router-dom";

import { Project } from "../../../../store/types/projects";
import { fileSeparator } from "../ProjectWindow";
import { ProjectExplorerSettingsContext } from "store/ProjectExplorerSettingsContext";
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const hashContainedInProjects = (
  projects: Project[],
  topic_id: string,
  hash: string
) => {
  if (!hash) {
    return false;
  } else if (hash === "#") {
    return false;
  }
  const hashedDirectory = hash.split("#")[1];
  const paths = hashedDirectory.split("-");
  const hashedTopic = paths[0].replace("%20", " ");
  if (topic_id === hashedTopic) {
    return true;
  } else if (paths.length > 2) {
    const hashedProject = paths.at(-1);
    return (
      projects.find((project) => project.name === hashedProject) !== undefined
    );
  }
  return false;
  // const paths;
};

function SideNavbar({
  handleChangeDirectory,
  projectsByTopic,
}: {
  handleChangeDirectory: (directory: string) => void;
  projectsByTopic: Map<string, Project[]>;
}) {
  const { hash } = useLocation();
  const { settings } = useContext(ProjectExplorerSettingsContext);
  const [openStates, setOpenStates] = useState(() => {
    const openStates = {};
    for (const [topic_name, projects] of projectsByTopic.entries()) {
      const open = hashContainedInProjects(projects, topic_name, hash);
      openStates[topic_name] = open;
    }
    return openStates;
  });

  const collapsibles = [];
  for (const [topicName, projects] of projectsByTopic) {
    collapsibles.push(
      <li key={topicName}>
        <Collapsible
          name={topicName}
          open={openStates[topicName]}
          handleCollapsible={(e: MouseEvent, topic_name: string) => {
            e.preventDefault();
            setOpenStates(() => {
              return { ...openStates, [topic_name]: !openStates[topic_name] };
            });
          }}
          handleChangeDirectory={handleChangeDirectory}
        >
          {projects.map((project) => {
            if (project.topic.name === topicName) {
              return (
                <li
                  key={project.name}
                  className={
                    settings.filter !== "NONE" &&
                    project.technologies.some((technology) =>
                      settings.filter.some(
                        (id: string) => id === String(technology.id)
                      )
                    )
                      ? "highlight"
                      : ""
                  }
                >
                  <a
                    href={`#${project.topic.name}-${project.name}`}
                    onClick={() =>
                      handleChangeDirectory(
                        project.topic.name + fileSeparator + project.name
                      )
                    }
                  >
                    {project.name}
                  </a>
                </li>
              );
            }
          })}
        </Collapsible>
      </li>
    );
  }

  return (
    <nav>
      <ul style={{ paddingLeft: "0.4rem" }}>
        <li key="hub">
          <a
            className="cursor-pointer"
            href="#"
            onClick={() => {
              handleChangeDirectory("");
            }}
          >
            <FontAwesomeIcon icon={faHouse} /> Home
          </a>
        </li>
        {collapsibles}
      </ul>
    </nav>
  );
}

export default SideNavbar;
