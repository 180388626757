function Collapsible({
  name,
  open,
  handleCollapsible,
  handleChangeDirectory,
  children,
}: {
  name: string;
  open: boolean;
  handleCollapsible: (e: MouseEvent, name: string) => void;
  handleChangeDirectory: (name: string) => void;
  children;
}) {
  return (
    <details
      className="topicContainer"
      open={open}
      onClick={(e) => {
        handleCollapsible(e, name);
      }}
    >
      <summary>
        <a
          href={`#${name}`}
          onClick={() => {
            handleChangeDirectory(name);
          }}
        >
          {name}
        </a>
      </summary>
      {
        <ul
          className="sidebarProjectContainer"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {children}
        </ul>
      }
    </details>
  );
}

export default Collapsible;
