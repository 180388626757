import { useEffect, useState } from "react";
import "./BackToTop.css";

const BackToTop = () => {
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    //credits: https://www.w3schools.com/howto/tryit.asp?filename=tryhow_js_scroll_to_top
    const scrollFunction = () => {
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    };
    document.addEventListener("scroll", scrollFunction);
    // When the user clicks on the button, scroll to the top of the document
    return () => {
      document.removeEventListener("scroll", scrollFunction);
    };
  }, []);

  const backToTop = () => {
    document.body.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div id="backToTop-container" className={visible ? "visible" : ""}>
      <button type="button" onClick={backToTop}>
        ^
      </button>
    </div>
  );
};

export default BackToTop;
