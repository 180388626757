import "./Skillbar.css";

const Skillbar = ({ name, rating }: { name: string; rating: number }) => {
  return (
    <div className="skillbar-container">
      <div className="rating-box" style={{ "--rating": rating }}>
        <span className="rating">{rating}</span>
      </div>
      <p style={{ margin: 0 }}>{name}</p>
    </div>
  );
};

export default Skillbar;
