import { useEffect, useRef, useState } from "react";

import "./Carousel.css";
import { ProjectImageInfo } from "../store/types/projectImageInfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faCircleDot } from "@fortawesome/free-solid-svg-icons";

/**
 * Utility function to create responsive and readible images based on the image width based on the viewport, the image, and a minimum size.
 * @param carouselWidthPercentage
 * @param minimumImagesSizeInPixels
 * @returns
 */
const calculateImageWidth = (
  carouselWidthPercentage: number,
  minimumImagesSizeInPixels: number = 350
) => {
  const carouselWidth =
    document.body.clientWidth * (carouselWidthPercentage / 100);
  let imagesDisplayed = 2.5; // 2.5 images shown if possible
  // if the screen is too small to show images properly (based on minimum size of image)
  if (minimumImagesSizeInPixels * imagesDisplayed > carouselWidth) {
    imagesDisplayed = carouselWidth / minimumImagesSizeInPixels;
  }

  const imageSize = carouselWidth / imagesDisplayed;
  // if (imageSize > originalImageWidth) {
  //   return originalImageWidth;
  // }
  return imageSize;
};

/**
 * Translates the mouse
 * @param e
 * @param transform
 * @param speed
 * @param imageWidth
 * @returns
 */
const translateMouseMove = (
  carouselContainer: HTMLElement,
  movementX: number,
  originalTranslateX: number,
  speed: number,
  imageWidth: number
) => {
  const imageScrollLimit = 1.0; // float indicating the minimum amount of images that should stay in view.
  const maxScrollWidth =
    carouselContainer.scrollWidth - imageScrollLimit * imageWidth;
  const potentialTranslateX = movementX * speed + originalTranslateX;
  let translateX;
  if (potentialTranslateX > 0) {
    translateX = 0;
  } else if (potentialTranslateX < -maxScrollWidth) {
    translateX = -maxScrollWidth;
  } else {
    translateX = potentialTranslateX;
  }
  return translateX;
};

const findCurrentFocussedImage = (imageWidth, translateX) => {
  const currentImageIndex = Math.round(Math.abs(translateX) / imageWidth);
  return currentImageIndex;
};

/**
 * This is an image carousel to display multiple images. The caller should provide images of
 * sufficient quality for the required carouselWidthPercentage.
 * @param images
 * @param aspectratio The desired aspect ratio that should be respected.
 * @param carouselWidthPercentage The percentage of the viewport the carousel will try and take in.
 * @returns
 */
const Carousel = ({
  images,
  aspectratio,
  carouselWidthPercentage,
  minimumImagesSizeInPixels,
}: {
  images: ProjectImageInfo[];
  aspectratio: number;
  carouselWidthPercentage: number;
  minimumImagesSizeInPixels: number;
}) => {
  // Image values
  const [imageWidth, setImageWidth] = useState(
    calculateImageWidth(carouselWidthPercentage, minimumImagesSizeInPixels)
  );
  const [translateX, setTranslateX] = useState<number>(() => 0);
  const imageHeight = imageWidth / aspectratio;
  const currentFocussedImage = findCurrentFocussedImage(imageWidth, translateX);
  const translateXSpeed = 1.5;

  // Escapes
  const carouselContainerRef = useRef<HTMLElement>(); // escape to add specific events listener to the carousel container
  const mouseMoveHandlerRef = useRef<(e: MouseEvent) => void>(); // have function not trigger useEffect

  // Change image width based on carouselWidthPercentage
  useEffect(() => {
    const handleResize = () => {
      setImageWidth(
        calculateImageWidth(carouselWidthPercentage, minimumImagesSizeInPixels)
      );
    };
    window.addEventListener("resize", handleResize);
    //--> some browser do not fire the resize event on orientation change
    window.addEventListener("orientationchange", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("orientationchange", handleResize);
    };
  }, [carouselWidthPercentage]);

  // Change image position based on mouseMovement
  useEffect(() => {
    const imageElements = carouselContainerRef.current
      ?.children as HTMLCollectionOf<HTMLElement>;
    if (!imageElements) {
      console.warn(
        "No image element were found when trying update position of images"
      );
    }
    for (const imageElement of imageElements) {
      imageElement.style.transform = `translateX(${translateX}px)`;
    }
  }, [translateX]);

  // Handle mouse down event inside the carousel container.
  const mouseDownHandler = (e: MouseEvent) => {
    e.preventDefault();

    const imageElements = carouselContainerRef.current
      ?.children as HTMLCollectionOf<HTMLElement>;
    if (!imageElements) {
      return;
    }
    const carouselContainer = carouselContainerRef.current;
    if (!carouselContainer) {
      throw new Error("No carouselContainerRef");
    }

    const mouseMoveHandler = (e: MouseEvent) => {
      const movementX = e.movementX;

      setTranslateX((prevTranslateX) => {
        const newTranslateX = translateMouseMove(
          carouselContainer,
          movementX,
          prevTranslateX,
          translateXSpeed,
          imageWidth
        );
        return newTranslateX;
      });
    };
    //update the reference used for cleaning up the listener later
    mouseMoveHandlerRef.current = mouseMoveHandler;

    carouselContainer.addEventListener("mousemove", mouseMoveHandler);
    document.addEventListener("mouseup", mouseUpHandler, { once: true });
  };

  // Clean up the listener set on mousedown
  const mouseUpHandler = (e: MouseEvent) => {
    if (!mouseMoveHandlerRef.current || !carouselContainerRef.current) {
      return;
    }
    carouselContainerRef.current.removeEventListener(
      "mousemove",
      mouseMoveHandlerRef.current
    );
  };

  return (
    <div className="carousel">
      <div
        className="img-carousel-container"
        ref={carouselContainerRef}
        onMouseDown={mouseDownHandler}
      >
        {images.map((image: ProjectImageInfo) => {
          return (
            <div className="img-container" key={image.src}>
              <img
                height={imageHeight}
                width={imageWidth}
                alt="image 1"
                src={image.src}
                style={{ aspectRatio: aspectratio }}
              />
              <div className="overlay">
                <p>{image.description}</p>
                <button type="button" onClick={image.onclick}>
                  learn more
                </button>
              </div>
            </div>
          );
        })}
      </div>
      <div className="carousel-navigation-bar">
        {images.map((image, index) => {
          return currentFocussedImage === index ? (
            <FontAwesomeIcon key={image.src} icon={faCircle} size="lg" />
          ) : (
            <FontAwesomeIcon
              key={image.src}
              icon={faCircleDot}
              size="lg"
              onClick={() => {
                setTranslateX(-(imageWidth * index));
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Carousel;
