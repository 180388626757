import { Reducer, useReducer } from "react";
import {
  DEFAULT_SETTINGS,
  ProjectExplorerSettingsContext,
  SetSetting,
  Settings,
} from "./ProjectExplorerSettingsContext";
import { useLocation } from "react-router";

const settingsReducer = (settings: Settings, action: SetSetting): Settings => {
  switch (action.type) {
    case "SORT":
      return { ...settings, sort: action.option };
    case "FILTER":
      return { ...settings, filter: action.option };
    case "VIEW":
      return { ...settings, view: action.option };
    case "CONFIG":
      return {
        ...settings,
        config: { ...settings.config, [action.setting]: action.option },
      };
    default:
      throw new Error("Unknown setting in project explorer");
  }
};

const ProjectExplorerSettingsProvider = ({ children }) => {
  const { search } = useLocation();

  // try to find the current filter based on URL
  const searchFilterTechnologyId = new URLSearchParams(search).get(
    "filter_technology_id"
  );

  // initalize filter based on URL (if applicable) else NONE
  const initialFilter = searchFilterTechnologyId
    ? [searchFilterTechnologyId]
    : "NONE";

  const initial_settings: Settings = {
    ...DEFAULT_SETTINGS,
    filter: initialFilter,
  };

  const [settings, dispatchSetting] = useReducer<Reducer<Settings, SetSetting>>(
    settingsReducer,
    initial_settings,
    undefined
  );
  return (
    <ProjectExplorerSettingsContext.Provider
      value={{ settings, dispatchSetting }}
    >
      {children}
    </ProjectExplorerSettingsContext.Provider>
  );
};

export default ProjectExplorerSettingsProvider;
