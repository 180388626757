// src: https://vitejs.dev/guide/assets.html#the-public-directory
export default function getImageUrl(name: string) {
  const url = new URL(
    import.meta.env.VITE_BASE + "/assets/" + name,
    import.meta.url
  ).href;
  return url;
}

export function importLocale(locale: string) {
  return import(`./locales/${locale}.js`);
}
