import "./Skills.css";
import useTriggerAnimationOnScrollIntoView from "../hooks/useTriggerAnimationOnScrollIntoView";
import { useEffect, useState } from "react";
import { TopicWithSkills } from "store/types/Topic";
import Skillbar from "./Skillbar";
// import { Id } from "react-toastify";

const Skills = ({ styleContainer }: { styleContainer: object }) => {
  const [skillsGroupedByTopic, setSkillGroupedByTopic] =
    useState<TopicWithSkills[]>();
  useTriggerAnimationOnScrollIntoView("#skills-container", "animate-skillbars");

  useEffect(() => {
    let mount = true;
    fetch("/api/v1/skills/groupedbytopic")
      .then((response) => response.json())
      .then((skills) => {
        if (mount) {
          setSkillGroupedByTopic(skills);
        }
      })
      .catch((err) => {
        console.error("unable to fetch Skills, ", err);
      });
    return () => {
      mount = false;
    };
  }, []);

  return (
    <div id="skills-container" style={styleContainer}>
      {skillsGroupedByTopic &&
        skillsGroupedByTopic.map((topicWithSkills) => {
          const skills = topicWithSkills.skills;
          return (
            <div
              key={topicWithSkills.id}
              className="skill-stack-container"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                gap: "0.8rem",
              }}
            >
              <div>
                <h3 style={{ marginTop: "0" }}>{topicWithSkills.name}</h3>
                <div>
                  {skills?.map((skill) => (
                    <Skillbar
                      key={skill.id}
                      name={skill.name}
                      rating={skill.rating}
                    />
                  ))}
                </div>
              </div>
              <div>
                <a
                  href={`projects#${topicWithSkills.name}`}
                  className="btn"
                  style={{ display: "inline-block" }}
                >
                  learn more
                </a>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default Skills;
