import { useState, useEffect } from "react";

type Event =
  | { type: "ADD_TEXT"; text: string }
  | { type: "PAUSE"; timeMs: number }
  | { type: "BEGIN_QUEUE" };

const createEventQueue = (text: string, speed: number) => {
  const eventQueue: Event[] = [];
  eventQueue.push({ type: "BEGIN_QUEUE" });
  for (const char of text) {
    eventQueue.push({ type: "ADD_TEXT", text: char });
    eventQueue.push({ type: "PAUSE", timeMs: speed });
  }
  return eventQueue;
};

const useTypewriter = (text: string, speed = 80) => {
  const [displayText, setDisplayText] = useState("");
  const [eventQueue, setEventQueue] = useState<Event[] | null>(null);

  useEffect(() => {
    const eventQueue = createEventQueue(text, speed);
    setEventQueue(eventQueue);
  }, [text, speed]);

  useEffect(() => {
    if (!eventQueue || eventQueue.length === 0) {
      return;
    }
    //execute the next element in the event queue
    const firstEventInQueue = eventQueue[0];
    const clearFromQueue = () => {
      setEventQueue((prevEventQueue) => {
        if (!prevEventQueue) {
          return null;
        }
        return prevEventQueue.slice(1);
      });
    };
    switch (firstEventInQueue.type) {
      case "BEGIN_QUEUE":
        setDisplayText("");
        clearFromQueue();
        break;
      case "ADD_TEXT":
        setDisplayText((prevText) => prevText + firstEventInQueue.text);
        clearFromQueue();
        break;
      case "PAUSE":
        setTimeout(clearFromQueue, firstEventInQueue.timeMs);
        break;

      default:
        throw new Error("Unknown event");
    }
  }, [eventQueue]);

  return displayText;
};

export default useTypewriter;
