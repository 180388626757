import ProjectWindow from "components/projects/project explorer/ProjectWindow";

const Projects = () => {
  return (
    <>
      <ProjectWindow />
    </>
  );
};

export default Projects;
