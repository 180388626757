import { useEffect, useState } from "react";
import ProjectExplorer from "./ProjectExplorer";
import { Project } from "store/types/projects";
import { Technology } from "store/types/technologies";
import ProjectExplorerSettingsProvider from "store/ProjectExplorerSettingsProvider";

let isUsersOperatingSystemWindows = false;
if (window.navigator.userAgent.toLowerCase().indexOf("windows")) {
  isUsersOperatingSystemWindows = true;
}
export const fileSeparator = isUsersOperatingSystemWindows ? "\\" : "/";

export const root = `explorer:${fileSeparator}`;

/**
 * Fetches technologies and projects, and establishes the settings context
 * @returns
 */
const ProjectWindow = () => {
  const [projects, setProjects] = useState<Project[]>([]);
  const [technologies, setTechnologies] = useState<Technology[]>([]);

  useEffect(() => {
    let mount = true;

    const technologiesRequest = fetch("/api/v1/technologies").then((response) =>
      response.json()
    );
    const projectsRequest = fetch("/api/v1/projects").then((response) =>
      response.json()
    );

    Promise.all([technologiesRequest, projectsRequest]).then(
      ([technologies, projects]) => {
        if (mount) {
          setTechnologies(technologies);
          setProjects(projects);
        }
      }
    );

    return () => {
      mount = false;
    };
  }, []);

  if (!projects) {
    return <p>window placeholder element...</p>;
  }

  return (
    <ProjectExplorerSettingsProvider>
      <ProjectExplorer projects={projects} technologies={technologies} />
    </ProjectExplorerSettingsProvider>
  );
};

export default ProjectWindow;
