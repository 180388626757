import { useEffect, useState } from "react";
import TechnologyIcon from "./TechnologyIcon";
import getImageUrl from "helpers/getImageUrl";
import { Technology } from "store/types/technologies";

type TechnologyGroupedByTheme = {
  theme: string;
  technologies: Technology[];
};

const Technologies = () => {
  //not the most efficient way, but do not want to write more code :P
  const [technologiesPerTheme, setTechnologiesPerTheme] = useState<
    TechnologyGroupedByTheme[]
  >([]);
  useEffect(() => {
    let mount = true;
    fetch("/api/v1/technologies/groupedbytheme")
      .then((response) => response.json())
      .then((technologies) => {
        if (mount) {
          setTechnologiesPerTheme(technologies);
        }
      });

    return () => {
      mount = false;
    };
  }, []);

  return (
    <>
      <div id="technologies-container">
        {technologiesPerTheme &&
          technologiesPerTheme.map((theme) => {
            const technologies = theme.technologies;
            const themeName = theme.theme;
            return (
              <div className="card" key={theme.theme}>
                <h3 style={{ marginTop: "0" }}>{themeName}</h3>
                <ul
                  style={{
                    listStyle: "none",
                    paddingLeft: "0",
                    margin: "0",
                    display: "flex",
                    justifyContent: "space-evenly",
                  }}
                >
                  {technologies.map((technology) => {
                    const url = getImageUrl(
                      `icons/${technology.icon_filename}.svg`
                    );
                    return (
                      <li key={technology.name}>
                        <TechnologyIcon
                          key={technology.name}
                          src={url}
                          name={technology.name}
                          tooltip={technology.tooltip}
                        />
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default Technologies;
