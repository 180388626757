//src: https://rapidapi.com/guides/interceptors-fetch
const { fetch: originalFetch } = window;

console.warn(
  "The implementation uses a fetch-interceptor to rewrite the origin of the API to: " +
    import.meta.env.VITE_API_BASE
);

/**
 * A mock version is used to rewrite the request to the server based on the environment.
 * This was initially necessary because github pages served the pages at bjornvanbraak.github.io/new-portfolio-website.
 *
 * @param args
 * @returns
 */
window.fetch = async (...args) => {
  let [resource, options] = args;

  //rewrite the fetch resource based on the api_base
  if (isApiRequest(resource)) {
    resource = rewriteApiResource(resource);
    console.info("API REQUEST, resource: " + resource);
  }
  const response = await originalFetch(resource, options);
  return response;
};

const isApiRequest = (resource: RequestInfo | URL) => {
  const isApiUrl = (url: string) => {
    return url.startsWith("/api") || url.startsWith("api");
  };

  if (typeof resource == "string") {
    return isApiUrl(resource);
  } else if (resource instanceof Request) {
    const url = resource.url;
    return isApiUrl(url);
  } else if (resource instanceof URL) {
    const url = resource.toString();
    return isApiUrl(url);
  } else {
    throw new Error(
      "FetchInterceptorException: unknown type/instanceof of resource: ",
      resource
    );
  }
};

const rewriteApiResource = (resource: RequestInfo | URL) => {
  const baseURL = import.meta.env.VITE_API_BASE;
  if (typeof resource == "string") {
    const url = baseURL + resource;
    return url;
  } else if (resource instanceof Request) {
    const url = baseURL + resource.url;
    return new Request(url, resource);
  } else if (resource instanceof URL) {
    return new URL(resource.pathname, baseURL);
  } else {
    throw new Error(
      "FetchInterceptorException: unknown type/instanceof of resource: ",
      resource
    );
  }
};
