import { useContext } from "react";
import {
  FilterOptions,
  ProjectExplorerSettingsContext,
} from "store/ProjectExplorerSettingsContext";
import { fileSeparator, root } from "../ProjectWindow";
import { Project } from "store/types/projects";
import ProjectDisplay from "./ProjectDisplay";
import ProjectHub from "./ProjectHub";

const filterOnTechnology = (
  projects: Project[],
  filterSetting: FilterOptions
) => {
  if (filterSetting === "NONE") {
    return projects;
  }
  const filterTechnologies = filterSetting;
  const filteredProjects = projects.filter((project) =>
    project.technologies.some((technology) => {
      if (!filterTechnologies) {
        return project;
      }
      return filterTechnologies.some((id) => id === String(technology.id));
    })
  );
  return filteredProjects;
};

const filterOnDirectory = (
  projects: Project[],
  directory: string
): Project[] => {
  if (!directory) {
    return projects;
  } else {
    const paths = directory.split(fileSeparator);
    if (paths.length === 1) {
      const topic = paths[0];
      const projectsInScope = projects.filter(
        (project: Project) => project.topic.name === topic
      );
      return projectsInScope;
    } else {
      //temp not sure what to do with multiple layers
      const name = paths.at(-1);
      const currentProject = projects.find((project) => project.name === name);
      if (!currentProject) {
        return [];
      } else {
        return [currentProject];
      }
    }
  }
};

const findProjectsInScope = (
  projects: Project[],
  directory: string,
  filterSetting: FilterOptions
) => {
  // console.log("DIRECTORY IN FIND PROJECTS IN SCOPE: ", directory);
  // console.log("FILTERSETTINGS: ", filterSetting);
  const filteredOnTechnologies = filterOnTechnology(projects, filterSetting);
  // console.log("FILETERED ON TECHNOLOGIES: ", filteredOnTechnologies);
  const filteredProjects = filterOnDirectory(filteredOnTechnologies, directory);
  // console.log("FILTERED ON PROJECTS: ", filteredProjects);
  return filteredProjects;
};

const ProjectExplorerDisplay = ({
  projects,
  activeProjectName,
  filePath,
  handleChangeDirectory,
}: {
  projects: Project[];
  activeProjectName: string | undefined;
  filePath: string;
  handleChangeDirectory: (directory: string) => void;
}) => {
  const {
    settings: { filter },
  } = useContext(ProjectExplorerSettingsContext);

  const directory = filePath.split(root)[1];

  const projectsInScope = findProjectsInScope(projects, directory, filter);

  return (
    <>
      {projectsInScope.length === 0 ? (
        <p>no project results</p>
      ) : activeProjectName !== undefined ? (
        <ProjectDisplay
          currentProject={(() => {
            const project = projects.find(
              (project) => project.name === activeProjectName
            );
            if (!project) {
              throw new Error("no project found, error in state exiting...");
            }
            return project;
          })()}
        />
      ) : (
        <ProjectHub
          projects={projectsInScope}
          handleChangeDirectory={handleChangeDirectory}
        />
      )}
    </>
  );
};

export default ProjectExplorerDisplay;
