import { Project } from "../../../../store/types/projects";

const ProjectTile = ({ project }: { project: Project }) => {
  console.log(project);
  return (
    <article>
      <h2>{project.name}</h2>
      <p>{project.description}</p>
    </article>
  );
};

export default ProjectTile;
