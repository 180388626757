import React from "react";
import { ThemeContext } from "./ThemeContext";

/**
 * Load theme based on previous visits preference.
 * Or guess preference based on user browser settings.
 * @returns
 */
const getTheme = () => {
  const saved_theme = localStorage.getItem("theme");
  if (saved_theme && (saved_theme === "light" || saved_theme === "dark")) {
    return saved_theme;
  } else {
    const prefered_theme = window.matchMedia("(prefers-color-scheme: light)")
      .matches
      ? "light"
      : "dark";
    return prefered_theme;
  }
};

const store = {
  getSnapshot: () => getTheme(),
  subscribe: (listener: () => void) => {
    const checkTheme = (event: StorageEvent) => {
      if (event.key === "theme") {
        listener();
      }
    };
    window.addEventListener("storage", checkTheme);
    return () => {
      window.removeEventListener("storage", checkTheme);
    };
  },
};

const ThemeProvider = ({ children }) => {
  // const [theme, setTheme] = React.useState(getInitialTheme());
  const theme = React.useSyncExternalStore<"light" | "dark">(
    store.subscribe,
    store.getSnapshot
  );

  const toggleTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    localStorage.setItem("theme", newTheme);
    // setTheme(newTheme);
    window.dispatchEvent(
      new StorageEvent("storage", { key: "theme", newValue: newTheme })
    );
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      <div id="theme-root" className={`${theme}-theme`}>
        {children}
      </div>
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
