import TimelineItem from "./TimelineItem";

import "./Timeline.css";
import useTriggerAnimationOnScrollIntoView from "../../hooks/useTriggerAnimationOnScrollIntoView";

import footballPhoto from "../../assets/voetbal_portretfoto.jpeg";
import bsc_graduation_photo from "../../assets/bsc_graduation_photo.jpg";

export type TimelineItemType = {
  topic: string;
  description: string;
  period_start: Date;
  period_end: Date | undefined;
  src: string;
};

const items: TimelineItemType[] = [
  {
    topic: "Football",
    description: "something aopic",
    period_start: new Date(2000, 0, 1),
    period_end: new Date(2012, 0, 1),
    src: footballPhoto,
  },
  {
    topic: "High School",
    description: "something about the topic",
    period_start: new Date(2012, 0, 1),
    period_end: new Date(2018, 0, 1),
    src: "",
  },
  {
    topic: "Advanced Technology",
    description: "something topic",
    period_start: new Date(2018, 0, 1),
    period_end: new Date(2020, 0, 1),
    src: "",
  },
  {
    topic: "Board Year",
    description: "something about the topic",
    period_start: new Date(2019, 0, 1),
    period_end: new Date(2020, 0, 1),
    src: "",
  },
  {
    topic: "Switch to Business Information Technology",
    description: "som about the topic",
    period_start: new Date(2020, 0, 1),
    period_end: new Date(2023, 0, 1),
    src: bsc_graduation_photo,
  },
  {
    topic: "Master Business Information Technology",
    description: "somethingthe topic",
    period_start: new Date(2023, 0, 1),
    period_end: undefined,
    src: "",
  },
];

type Direction = "ltr" | "rtl";

const Timeline = () => {
  useTriggerAnimationOnScrollIntoView(
    ".timeline-item-container",
    "animate-fadein"
  );

  return (
    <div className="timeline-container">
      <ul style={{ "--num-timeline-items": items.length }}>
        {items.map((item: TimelineItemType, index: number) => {
          const perspective = index % 2 === 0 ? "left" : "right";
          const direction: Direction = perspective === "left" ? "rtl" : "ltr";
          return (
            <li
              key={item.topic}
              className={`timeline-item`}
              style={{ "--direction-timeline-item": direction }}
            >
              <div
                className={`timeline-item-container ${
                  perspective === "left" ? "fadeInLeft" : "fadeInRight"
                }`}
              >
                <TimelineItem item={item} />
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Timeline;
