import "./Chatbot.css";

const Chatbot = () => {
  return (
    <div id="chatbot-container">
      <div>
        <p>Chatbot is coming soon</p>
      </div>
      <form>
        <input
          disabled
          type="text"
          placeholder="please enter a question..."
          max={100}
        />
        <button disabled type="submit">
          ⏎
        </button>
      </form>
    </div>
  );
};

export default Chatbot;
