import { faArrowLeft } from "@fortawesome/free-solid-svg-icons/faArrowLeft";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons/faArrowRight";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons/faArrowUp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { root, fileSeparator } from "../ProjectWindow";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import "./ToolbarNav.css";

import { Project } from "store/types/projects";

// credits: https://dev.to/elsyng/react-modal-dialog-using-html-dialog-element-5afk
const isClickInsideRectangle = (e: MouseEvent, element: HTMLElement) => {
  const r = element.getBoundingClientRect();

  return (
    e.clientX > r.left &&
    e.clientX < r.right &&
    e.clientY > r.top &&
    e.clientY < r.bottom
  );
};

const isValidDirectory = (
  projectsByTopic: Map<string, Project[]>,
  possibleDirectory: string
) => {
  // a valid directory is considered to be either "" or starting with one of the topics
  // optionally there could be a project added
  // unsure how to do it with subdirectories at this point, but it is not necessary yet
  if (possibleDirectory === "") {
    return true;
  } else {
    const subPaths = possibleDirectory.split(fileSeparator);
    const possibleTopic = subPaths[0];
    if (projectsByTopic.has(possibleTopic)) {
      if (subPaths.length === 1) {
        return true;
      } else if (subPaths.length === 2) {
        const possibleProject = subPaths[1];
        const validProjects = projectsByTopic.get(possibleTopic);
        const hasProject = validProjects?.find(
          (project) => project.name === possibleProject
        );
        if (hasProject) {
          return true;
        }
        return false;
      } else {
        throw new Error("3rd layer directories are not defined yet");
      }
    }
    return false;
  }
};

const createDirectories = (topicToProjectsMap: Map<string, Project[]>) => {
  const directories = [];

  for (const [topic, projects] of topicToProjectsMap) {
    if (!projects) {
      directories.push(topic);
      continue;
    }
    for (const project of projects) {
      const directory = topic + fileSeparator + project.name;
      directories.push(directory);
    }
  }
  return directories;
};

const proposeDirectories = (
  projectsByTopic: Map<string, Project[]>,
  inputFilePath: string
) => {
  const inputDirectory = inputFilePath.replace(root, "");
  const subPaths = inputDirectory.split(fileSeparator);
  const possibleTopic = subPaths[0];
  if (possibleTopic === "") {
    //return all topics
    const topics = projectsByTopic.keys();
    const map = new Map();
    for (const topic of topics) {
      map.set(topic, null);
    }
    return createDirectories(map);
  } else {
    if (!projectsByTopic.has(possibleTopic)) {
      // const directories = [];
      // for (const topic of projectsByTopic.keys()) {
      //   if (topic.startsWith(possibleTopic)) {
      //     directories.push(topic);
      //   }
      // }
      const map = new Map();
      for (const topic of projectsByTopic.keys()) {
        if (topic.startsWith(possibleTopic)) {
          map.set(topic, null);
        }
      }
      return createDirectories(map);
    }
    const topic = possibleTopic;
    const projects = projectsByTopic.get(topic)!;
    if (
      subPaths.length === 1 ||
      (subPaths.length === 2 && subPaths[1] === "")
    ) {
      //return all the directories of that topic
      const map = new Map().set(topic, projects);
      return createDirectories(map);
    } else if (subPaths.length === 2) {
      //return the project if the second subpath is the start of the project name
      const substring = subPaths[1];
      const matchedProjects = projects.filter((project) =>
        project.name.startsWith(substring)
      );
      const map = new Map().set(topic, matchedProjects);
      return createDirectories(map);
    } else {
      console.warn("deeper subdirectories have not been implemented yet");
      return [];
    }
  }
  return [];
};

const proposeSearchDirectories = (
  projectsByTopic: Map<string, Project[]>,
  search: string
) => {
  if (search === "") {
    return [];
  }
  const map = new Map();
  for (const [topic, projects] of projectsByTopic) {
    const matchedProjects = [];
    for (const project of projects) {
      if (project.name.startsWith(search)) {
        matchedProjects.push(project);
      }
    }
    map.set(topic, matchedProjects);
  }
  return createDirectories(map);
};

const handleArrows = (
  e: KeyboardEvent,
  lengthArr: number,
  setIndex: Dispatch<SetStateAction<number | undefined>>
) => {
  switch (e.key) {
    case "ArrowDown":
      setIndex((index) => {
        if (index === undefined) {
          return 0;
        }
        const newIndex = (index + 1) % lengthArr;
        return newIndex;
      });
      break;
    case "ArrowUp":
      setIndex((index) => {
        if (index === undefined) {
          return lengthArr - 1;
        }
        const newIndex = index - 1 >= 0 ? index - 1 : lengthArr - 1;
        return newIndex;
      });
      break;
  }
};

function ToolbarNav({
  goBackwards,
  goForwards,
  goUpOneLevel,
  handleChangeDirectory,
  filePath,
  projectsByTopic,
}: {
  goBackwards: () => void;
  goForwards: () => void;
  goUpOneLevel: () => void;
  handleChangeDirectory: (directory: string) => void;
  filePath: string;
  projectsByTopic: Map<string, Project[]>;
}) {
  const [isSelected, setIsSelected] = useState(false);
  const directoryNavRef = useRef<HTMLElement | null>(null);
  const [inputFilePath, setInputFilePath] = useState(filePath);
  const [search, setSearch] = useState("");
  const directorySearchInputRef = useRef<HTMLInputElement>();
  const projectSearchInputRef = useRef<HTMLInputElement>();

  const [inputIndex, setInputIndex] = useState<number | undefined>(undefined);
  const [searchIndex, setSearchIndex] = useState<number | undefined>(undefined);

  const suggestedDirectories = proposeDirectories(
    projectsByTopic,
    inputFilePath
  );

  const suggestedSearchDirectories = proposeSearchDirectories(
    projectsByTopic,
    search
  );

  let subPaths: string[] = [];
  if (filePath === root) {
    subPaths = [filePath.split(fileSeparator)[0]];
  } else {
    subPaths = filePath.split(fileSeparator);
  }

  const handleChangeDirectoryWrapper = (e, subPaths, currentIndex) => {
    e.stopPropagation();
    if (subPaths.length - 1 === currentIndex) {
      //prevent handleChangeDirectory if the requested page is already being displayed
      return;
    }
    let directory = "";
    for (let i = 1; i <= currentIndex; i++) {
      directory += subPaths[i];
    }
    handleChangeDirectory(directory);
  };

  useEffect(() => {
    if (!isSelected) {
      //only run if the toolbar-directory is selected
      return;
    }
    const onClickedOutside = (e: MouseEvent) => {
      if (!directoryNavRef.current) {
        console.warn("ref not defined inside useeffect");
        return;
      }
      if (!isClickInsideRectangle(e, directoryNavRef.current)) {
        setInputFilePath(filePath);
        resetDirectoryInput();
      }
    };

    document.addEventListener("click", onClickedOutside);
    return () => {
      document.removeEventListener("click", onClickedOutside);
    };
  }, [isSelected, filePath]);

  const handleKeyPressInput = (e) => {
    if (e.key === "ArrowDown" || e.key === "ArrowUp") {
      handleArrows(e, suggestedDirectories.length, setInputIndex);
    } else if (e.key === "Enter") {
      changeDirectory(e);
    }
  };

  const handleKeyPressSearch = (e) => {
    if (e.key === "ArrowDown" || e.key === "ArrowUp") {
      handleArrows(e, suggestedSearchDirectories.length, setSearchIndex);
    } else if (e.key === "Enter") {
      // changeDirectory(e);
    }
  };

  const handleClick = (e: MouseEvent) => {
    setIsSelected(true);
    e.stopPropagation();
  };

  const changeInputFilePath = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputFilePath(e.target.value);
    setInputIndex((inputIndex) => (inputIndex === undefined ? undefined : 0));
  };

  //synchronize with filepath
  useEffect(() => {
    setInputFilePath(filePath);
  }, [filePath]);

  const changeDirectory = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // e.preventDefault();
    if (!(e.key === "Enter")) {
      return;
    }
    let possibleDirectory;
    if (inputIndex === undefined) {
      possibleDirectory = inputFilePath.replace(root, "");
    } else {
      possibleDirectory = suggestedDirectories[inputIndex];
    }
    if (possibleDirectory.at(-1) === fileSeparator) {
      possibleDirectory = possibleDirectory.slice(0, -1);
    }
    if (isValidDirectory(projectsByTopic, possibleDirectory)) {
      handleChangeDirectory(possibleDirectory);
      setInputFilePath(possibleDirectory);
    } else {
      setInputFilePath(filePath);
    }
    resetDirectoryInput();
  };

  const resetDirectoryInput = () => {
    setIsSelected(false);
    setInputIndex(undefined);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setSearchIndex((searchIndex) =>
      searchIndex === undefined ? undefined : 0
    );
  };

  return (
    <div id="toolbarNav">
      <div id="navigationTools">
        <button onClick={() => goBackwards()}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>
        <button onClick={() => goForwards()}>
          <FontAwesomeIcon icon={faArrowRight} />
        </button>
        <button onClick={() => goUpOneLevel()}>
          <FontAwesomeIcon icon={faArrowUp} />
        </button>
      </div>
      <div id="toolbar-directory" ref={directoryNavRef} onClick={handleClick}>
        {isSelected ? (
          <div id="directory-search" className="search-box">
            <input
              type="text"
              className="search-input"
              value={inputFilePath}
              onChange={changeInputFilePath}
              onKeyDown={handleKeyPressInput}
              autoComplete="off"
              ref={directorySearchInputRef}
            />
            <ul id="directory-search-results" className="search-results">
              {suggestedDirectories.map((directory, index) => {
                return (
                  <li key={directory}>
                    <button
                      type="button"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleChangeDirectory(directory);
                        setIsSelected(false);
                        directorySearchInputRef.current.blur();
                      }}
                      className={inputIndex === index ? "highlighted" : ""}
                      onMouseDown={(e) => {
                        //prevent the onmousedown from bubling up before the onclick is fired.
                        e.preventDefault();
                      }}
                    >
                      {directory}
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
        ) : (
          <div
            id="current-directory"
            className="highlight"
            style={{ cursor: "pointer" }}
          >
            {subPaths.map((path, index) => (
              <span key={path + "-" + index}>
                <button
                  type="button"
                  onClick={(e) => {
                    handleChangeDirectoryWrapper(e, subPaths, index);
                  }}
                  disabled={subPaths.length - 1 === index}
                >
                  {path}{" "}
                </button>
                {index !== subPaths.length - 1 && <span>{">"}</span>}
              </span>
            ))}
          </div>
        )}
      </div>
      <div id="project-search" className="search-box">
        <input
          className="search-input"
          placeholder="search for project..."
          value={search}
          onChange={handleSearch}
          onKeyDown={handleKeyPressSearch}
          ref={projectSearchInputRef}
        />
        <ul id="project-search-results" className="search-results">
          {suggestedSearchDirectories.map((directory, index) => {
            return (
              <li key={directory}>
                <button
                  type="button"
                  className={searchIndex === index ? "highlighted" : ""}
                  onClick={() => {
                    handleChangeDirectory(directory);
                    // blur should be triggered on the input
                    projectSearchInputRef.current.blur();
                  }}
                  onMouseDown={(e) => {
                    //blur event should be prevent in order for onclick to execute
                    e.preventDefault();
                  }}
                >
                  {directory}
                </button>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

export default ToolbarNav;
