import Carousel from "../Carousel";
import { useContext, useEffect, useState } from "react";
import { Project } from "store/types/projects";
const aspectratio = 16 / 9;
import { ProjectImageInfo } from "../../store/types/projectImageInfo";
import getImageUrl from "helpers/getImageUrl";
import { useNavigate } from "react-router";
import { ThemeContext } from "store/ThemeContext";

const ProjectCarousel = () => {
  const [projects, setProjects] = useState<Project[]>();
  const navigate = useNavigate();
  const themeCtx = useContext(ThemeContext);

  useEffect(() => {
    let mount = true;
    fetch("/api/v1/projects/highlighted")
      .then((response) => response.json())
      .then((projects) => {
        if (mount) {
          setProjects(projects);
        }
      })
      .catch((err) => {
        console.error("unable to fetch projects, ", err);
      });
    return () => {
      mount = false;
    };
  }, []);
  const images = projects?.map((project) => {
    const projectImageInfo: ProjectImageInfo = {
      src: getImageUrl(
        project.image_filename + "-" + themeCtx.theme + "-500.jpeg"
      ),
      description: project.description,
      onclick: () => {
        navigate("/projects" + "#" + project.topic.name + "-" + project.name);
      },
    };
    return projectImageInfo;
  });
  if (!images) {
    return <p> no images</p>;
  }
  return (
    <Carousel
      images={images}
      aspectratio={aspectratio}
      carouselWidthPercentage={100}
      minimumImagesSizeInPixels={300}
    />
  );
};

export default ProjectCarousel;
