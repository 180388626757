const SettingsDropdownItem = ({
  selected,
  onClick,
  children,
}: {
  selected: boolean;
  onClick: () => void;
  children: any;
}) => {
  return (
    <button type="button" onClick={onClick}>
      <span className={selected ? "selected" : ""}>{children}</span>
    </button>
  );
};

export default SettingsDropdownItem;
