import { TimelineItemType } from "./Timeline";

const getPeriod = (item: TimelineItemType) => {
  const end = item.period_end ? item.period_end?.getFullYear() : "now";
  return `${item.period_start.getFullYear()}-${end}`; //produce unexpected behavior when space between (has to do with css direction, but do not know the details)
};

const TimelineItem = ({ item }: { item: TimelineItemType }) => {
  return (
    <p className="timeline-text-container">
      <span style={{ display: "inline-block" }} className="year">
        {"(" + getPeriod(item) + ")"}
      </span>
      <span style={{ display: "inline-block" }}>{item.topic}</span>
      <span
        style={{ display: "inline-block" }}
        className="timeline-image-container"
      >
        {item.src && (
          <img src={item.src} style={{ width: "4rem", height: "4rem" }} />
        )}
      </span>
    </p>
  );
};

export default TimelineItem;
