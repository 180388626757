import cv_light from "../assets/CV_LIGHT_Bjorn_van_Braak_V3.2.pdf";
import cv_dark from "../assets/CV_DARK_Bjorn_van_Braak_V3.2.pdf";

// import "./CV.css";
import { ThemeContext } from "store/ThemeContext";
import { useContext } from "react";

//credits: https://pspdfkit.com/blog/2021/how-to-embed-a-pdf-viewer-in-your-website/
const CV = () => {
  const themeCtx = useContext(ThemeContext);
  const cv = themeCtx.theme === "light" ? cv_light : cv_dark;
  const cvFullWidth = cv + "#view=FitH";
  return (
    <div
      id="cv-main-container"
      className={"section"}
      style={{
        marginTop: 0,
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <div style={{ display: "flex", justifyContent: "center" }}>
        <a
          href={cv}
          download="cv_bjorn_van_braak"
          className="btn"
          style={{ display: "inline-block" }}
        >
          Download cv
        </a>
      </div>
      <div id="pdf-cv-preview" style={{ flex: 1 }}>
        <object
          data={cvFullWidth}
          type="application/pdf"
          width="100%"
          height="100%"
        >
          <iframe
            src={cvFullWidth}
            width="100%"
            height="100%"
            style={{ border: "none" }}
          >
            <p>
              Your browser does not support PDFs.
              <a href={cv}>Download the PDF</a>
            </p>
          </iframe>
        </object>
      </div>
    </div>
  );
};

export default CV;
